import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Spin } from 'antd';

import { T } from '../../components/Translations';
import { Ajax, AjaxGetLanguagesFetch } from '../../components/Ajax';
import { ApiUrl } from '../../project/Defines.js';
import { useCustomEvent } from '../../project/utilities';
import { ValidationInput, validate } from '../Form/validation';
import store from '../../store';

import { showLoader, hideLoader } from 'Shared/Loader';
//import config from '~/Shared/Translation/config' // NOSONAR

const loaderName = 'TRANSLATION_LOADER';
const langs = [];

const api_url = process.env.REACT_APP_DEV_API_URL ?? process.env.REACT_APP_API_URL;
const api_app = process.env.REACT_APP_DEV_API_APP ?? process.env.REACT_APP_API_APP;

export function getLanguages() {
    return langs;
}

export function initLanguages() {
    let token = store.getState().userToken || store.getState().appToken || "test";
    AjaxGetLanguagesFetch({
        url: api_url + api_app + '/api/valuelist/getlist',
        token,
        callback: (list)=>{
            list.forEach(lang => {
                langs.push({
                    value: lang.value, text: <T>{lang.text}</T>, active: lang.active
                });
            });
        }
    });
}

export function getLanguageText(culture) {
    const cultures = getLanguages();
    let lang = cultures.find(c => c.value === culture);
    return (lang && lang.text) || culture;
}

/**
 *  represents the translation tab content for each detail page
 * the component used to add translations for each item
 * @param props contains:ID, _class, fields, showField, updateEvent, updateCallback
 * @exports  Translation
 */
export default function Translation(props) {
    const cultures = getLanguages();
    const { ID, _class, fields, showField, settings, /*translations, setTranslations, test,*/ updateEvent, updateCallback, refreshEvent, validation, setValidation, validationProps } = props;
    const [isLoading, setIsLoading] = useState(false);
    const transRef = useRef(null);
    const [translations, setTranslations] = useState([]);
    const [validationFields, setValidationFields] = useState([]);

    useEffect(() => {
        validationProps && setValidationFields(validationProps.map(vp => vp.rules ? ({ name: vp.lang + (vp.champ || fields[0]), rules: vp.rules }) : (vp.lang + (vp.champ || fields[0]))));
    }, [validationProps]);// eslint-disable-line react-hooks/exhaustive-deps

    const loading = <div style={{ "margin": "15% auto" }}><Spin size="large" /></div>;

    const onLoadData = useCallback((data) => {

        const newData = [];
        for (let i = 0; i < fields.length; i++) { // NOSONAR
            for (let x = 0; x < cultures.length; x++) { // NOSONAR
                let index = data.findIndex(p => p.lang === cultures[x].value && p.champ === fields[i]);
                if (index >= 0) {
                    newData.push(data[index]);
                } else {
                    newData.push({
                        _class, lang: cultures[x].value, traduction: "", class_uuid: ID, champ: fields[i]
                    });
                }
            }
        }
        setTranslations(newData);
        setIsLoading(false);
    }, [ID, fields, _class, cultures]);

    const updateData = useCallback((e) => { // NOSONAR
        if (!validation || validation.isValid) {
            if (e.detail.ID) {
                if (!isLoading) {
                    setIsLoading(true)
                }

                Ajax.post({
                    url: ApiUrl.TranslationsUpdate,
                    data: {
                        tdm: translations,
                        class_uuid: e.detail.ID
                    },
                    success: function (response) {
                        if (!response.HasError && transRef.current) {
                            onLoadData(response.Data);
                            updateCallback && updateCallback(response.Data);
                        }
                        if (transRef.current)
                            setIsLoading(false);
                    },
                    error: function () {
                        if (transRef.current)
                            setIsLoading(false);
                    }
                })
            }
        }
    }, [translations, onLoadData, isLoading, validation, updateCallback]);

    useCustomEvent(updateEvent, updateData);

    const loadData = useCallback(() => {
        if (!isLoading) {
            showLoader(loaderName);
        }

        if (ID) {
            Ajax.post({
                url: ApiUrl.TranslationList,
                data: {
                    filterData: JSON.stringify({ class_uuid: ID })
                },
                success: function (response) {
                    if (response && transRef.current) {
                        onLoadData(response.Data);
                    }
                    hideLoader(loaderName);
                },
                error: function () {
                    hideLoader(loaderName);
                }
            })
        } else {
            onLoadData([]);
            hideLoader(loaderName);
        }
    }, [ID, onLoadData, isLoading]);

    useCustomEvent(refreshEvent, loadData);

    useEffect(() => {
        loadData();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadData();
    }, [ID]);// eslint-disable-line react-hooks/exhaustive-deps

    const onChangeTranslation = useCallback((event) => {
        let newTranslations = [...translations];
        let index = newTranslations.findIndex(p => p.lang + p.champ === event.target.name);
        if (index >= 0) {
            newTranslations[index].traduction = event.target.value;
        }
        setTranslations(newTranslations);
        if (setValidation && validationFields.length) {
            let validatingData = {};
            newTranslations.forEach(t => { validatingData[t.lang + t.champ] = t.traduction })
            setValidation(validate({ data: validatingData, validationFields }));
        }
    }, [translations, validationFields, setValidation]);

    return (
        <div className="form_container1" ref={transRef} >
            <div className="section_group">
                {(isLoading && loading) || fields.map((field, i) => {
                    return <div className={"sections " + (settings && settings[field] && settings[field].section ? settings[field].section : "")} key={field + i} // NOSONAR
                    >
                        {showField && <div className="form_fields"><div className="section_title"><text>{field}</text></div><div className="form_separator"></div></div>}
                        {
                            translations.filter(t => t.champ === field).map((trans, i) => {
                                return <div key={field + trans.key + i} className="form_fields" // NOSONAR
                                >
                                    <div className="data_label">{getLanguageText(trans.lang)}</div>
                                    <ValidationInput validation={validation || { isValid: true }} maxLength={ (settings && settings[field] && settings[field].section ? settings[field].maxLength :150) } type="text" name={trans.lang + trans.champ} value={trans.traduction} onChange={onChangeTranslation} autoComplete="off" />
                                </div>
                                }
                            )
                        }
                    </div>
                })}

            </div>
        </div>
    );
}
