import React, { useCallback, useEffect, useRef, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, connect } from "react-redux";
import { message, Dropdown, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { T } from '../../components/Translations';
import { ApiUrl, AppPages, UserRoles } from '../../project/Defines';
import { Project } from '../../project/Project';
import { changeCulture, closeAllPopups, openPopup, changeUserToken } from '../../store/actions';

import Scroll from '../../Shared/Scroll/Scroll';
import { PopupClasses, PopupTypes } from '../Popup';

import { Ajax } from '../../components/Ajax';
import { showLoader, hideLoader } from 'Shared/Loader';

const loaderName = 'MENU_LOADER';

const LanguageChange = (props) => {

    let { culture } = props;
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const ref = useRef(false);

    useEffect(() => {
        ref.current = true;

        let language = i18n.language;
        if (language.indexOf("-") !== -1) {
            language = language.split('-')[0];
        }
        dispatch(changeCulture(culture || language));
        return () => { ref.current = false; }

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        i18n.changeLanguage(culture);
    }, [i18n, culture]);

    const changeLanguage = lng => {
        if (ref.current) {
            dispatch(changeCulture(lng));
        }
    };

    return <>
        <Tooltip placement="bottom" title="English">
            <div effect="material" className={culture === 'en' ? "button primary" : "button"} onClick={() => changeLanguage('en')} // NOSONAR
                ><text>en</text></div>
        </Tooltip>
        <Tooltip placement="bottom" title="French">
            <div effect="material" className={culture === 'fr' ? "button primary" : "button"} onClick={() => changeLanguage('fr')} // NOSONAR
                ><text>fr</text></div>
        </Tooltip>
    </>
}
/**
 * represents the control for selecting current interface language */
const LanguageChangeComponent = connect(state => ({
    culture: state.culture
}), null)(LanguageChange);

export const menuItems = [
    {
        to: AppPages.Brand,
        text: <T>text.brand</T>,
        detail: AppPages.BrandDetails,
        help: { title: <T>text.brand</T>, fr: "Brand_FR.pdf", en: "Brand_EN.pdf", video: "Video_Brand.mp4" }
    },
    { to: AppPages.SalesNetwork, text: <T>text.sales_network</T>, detail: AppPages.SalesNetworkDetails, help: { title: <T>text.sales_network</T>, fr: "SalesNetwork_FR.pdf", en: "SalesNetwork_EN.pdf" } },
    { to: AppPages.Universe, text: <T>text.universe</T>, detail: AppPages.UniverseDetails, help: { title: <T>text.universe</T>, fr: "Universe_FR.pdf", en: "Universe_EN.pdf" } },
    { to: AppPages.Products, text: <T>text.products</T>, detail: AppPages.ProductDetails, help: { title: <T>text.products</T>, fr: "Products_FR.pdf", en: "Products_EN.pdf" } },
    { to: AppPages.BillOfMaterials, text: <T>text.bill_of_materials</T>, detail: AppPages.BillOfMaterialDetails, pages: [AppPages.BillOfMaterialExtract], help: { title: <T>text.bill_of_materials</T>, fr: "BillOfMaterials_FR.pdf", en: "BillOfMaterials_EN.pdf" } },
    { to: AppPages.SpareParts, text: <T>text.spare_parts</T>, detail: AppPages.SparePartDetails, pages: [AppPages.SparePartImagesUpload], help: { title: <T>text.spare_parts</T>, fr: "SpareParts_FR.pdf", en: "SpareParts_EN.pdf" } },
    { to: AppPages.User, text: <T>text.users</T>, detail: AppPages.UserDetails, pages: [AppPages.UsersSiretChanges, AppPages.SocieteUserDetails], help: { title: <T>text.users</T>, fr: "Users_FR.pdf", en: "Users_EN.pdf" } },
    {
        text: <T>text.jobs</T>, subMenu: [
            { to: AppPages.JobsWarrantyRequest, text: <T>text.warranty_request</T>, selected: false, detail: AppPages.JobsWarrantyRequestDetails },
            { to: AppPages.JobsSalesOrder, text: <T>text.sales_order</T>, selected: false, detail: AppPages.JobsSalesOrderDetails, isDefault: true },
            { to: AppPages.JobsDevis, text: <T>text.devis</T>, selected: false },
        ], help: { title: <T>text.jobs</T>, fr: "Jobs_FR.pdf", en: "Jobs_EN.pdf" }
    },
    {
        text: <T>text.administration</T>, subMenu: [
            { to: AppPages.Administrators, text: <T>text.administrators</T>, selected: false, detail: AppPages.AdministratorsDetails, Roles: [UserRoles.SUPERADMIN] },
            { to: AppPages.AllowedIP, text: <T>text.allowed_ip</T>, selected: false, detail: AppPages.AllowedIPDetails },
            { to: AppPages.BankCard, text: <T>text.cb_reseau</T>, selected: false, detail: AppPages.BankCardDetails },
            { to: AppPages.ReseauConfigCBS, text: <T>text.cb_config</T>, selected: false, detail: AppPages.ReseauConfigCBSDetails },
            { to: AppPages.APIAccess, text: <T>text.api_access</T>, selected: false, detail: AppPages.APIAccessDetails },
            { to: AppPages.TVA, text: <T>text.tva</T>, selected: false, detail: AppPages.TVADetails },
            { to: AppPages.History, text: <T>text.history</T>, selected: false, detail: AppPages.HistoryDetails },
            { to: AppPages.ConnectivityBlocking, text: <T>text.connectivity_blocking</T>, selected: false, detail: AppPages.ConnectivityBlocking },
            { to: AppPages.Skills, text: <T>text.skills</T>, selected: false, detail: AppPages.SkillsDetails },
            { to: AppPages.Fees, text: <T>text.fees</T>, selected: false, detail: AppPages.FeeDetails },
            { to: AppPages.DefectCode, text: <T>text.defect_code</T>, selected: false, detail: AppPages.DefectCodeDetails },
            { to: AppPages.Coverage, text: <T>text.coverage</T>, selected: false, detail: AppPages.CoverageDetails },
            { to: AppPages.SSO, text: <T>text.sso</T>, selected: false, detail: AppPages.SSODetails },
            { to: AppPages.Maintenance, text: <T>text.maintenance</T>, selected: false, detail: AppPages.MaintenanceDetails }
        ], help: { title: <T>text.administration</T>, fr: "Administration_FR.pdf", en: "Administration_EN.pdf" }
    },
    {
        text: <T>text.setup</T>, subMenu: [
            { to: AppPages.SetupProductParameter, text: <T>text.product_parameter</T>, selected: false, detail: AppPages.SetupProductParameterDetails },
            { to: AppPages.SetupClientTypes, text: <T>text.client_types</T>, selected: false, detail: AppPages.SetupClientTypeDetails },
            { to: AppPages.SetupWarrantyTypes, text: <T>text.warranty_types</T>, selected: false, detail: AppPages.SetupWarrantyTypeDetails },
            { to: AppPages.SetupProductDefect, text: <T>text.product_defect</T>, selected: false, detail: AppPages.SetupProductDefectDetails },
            { to: AppPages.SetupArticleTypes, text: <T>text.articles_types</T>, selected: false, detail: AppPages.SetupArticleTypeDetails },
            { to: AppPages.SetupVideo, text: <T>text.video</T>, selected: false, detail: AppPages.SetupVideoDetails },
            { to: AppPages.SetupNormes, text: <T>text.standards</T>, selected: false, detail: AppPages.SetupNormDetails },
            { to: AppPages.SetupCountryGrouping, text: <T>text.country_grouping</T>, selected: false, detail: AppPages.SetupCountryGroupingDetails },
            { to: AppPages.SetupCountries, text: <T>text.countries</T>, selected: false, detail: AppPages.SetupCountryDetails },
            { to: AppPages.SetupRechercheNoSerie, text: <T>text.recherche_no_serie</T>, selected: false, detail: AppPages.SetupRechercheNoSerieDetails },
            { to: AppPages.SetupTranslation, text: <T>text.traduction_ihm</T>, selected: false },
            { to: AppPages.SetupLanguages, text: <T>text.languages</T>, selected: false, detail: AppPages.SetupLanguageDetails },
        ], help: { title: <T>text.setup</T>, fr: "Setup_FR.pdf", en: "Setup_EN.pdf" }
    },
    { to: AppPages.Process, text: <T>text.process</T>, detail: AppPages.ProcessDetails, help: { title: <T>text.process</T>, default: true } }
];

/**
 * The layout component specifies the site layout, top menu based on login state and active states of top menu items
 * @param {object} props  { isLoggedIn, popups, culture, role, etc.}
 */
function Menu(props) {
    const { role, closeAllPopups, userName, culture } = props;
    const dispatch = useDispatch();

    const openVideoPopup = useCallback(() => {
        dispatch(openPopup({
            window,
            title: menuItems[0].help.video,
            type: PopupTypes.HelpVideoPicker,
            fullScreen: false,
            popupClassName: PopupClasses.Medium,
            bodyProps: {
                menuItems: menuItems
            }
        }));
    }, [dispatch]);

    const connectAsUser = useCallback(() => {
        showLoader(loaderName);
        Project.refreshUserToken((token) => {
            dispatch(changeUserToken(token.access_token));
            Ajax.post({
                url: ApiUrl.ConnectAsUser,
                data: { portal: 'web_station', back_url: window.location.href, user_token: token.access_token },
                success: function (response) {
                    if (!response.hasError) {
                        setTimeout(() => { // NOSONAR
                            window.location.href = response;
                        }, 100);
                    } else {
                        message.error(response.message);
                        hideLoader(loaderName);
                    }
                },
                error: function (error) {
                    hideLoader(loaderName);
                }
            });
        });
    }, [dispatch]);

    let userMenu =
        <div className="list_menu ant-dropdown-menu">
            <ul>
                {/* <li command="user"><icon></icon><text><T>text.my_account</T></text></li> */}
                {
                    [UserRoles.DISPATCHER].includes(role) &&
                    <li onClick={() => connectAsUser()} // NOSONAR
                    ><icon></icon><text><T>text.web_station_portal</T></text></li>
                }
                <li className="separator"></li>
                <li command="easter" effect="material" className="disabled" bottom="" // NOSONAR
                ><icon>info</icon><text><T>text.version</T></text><separator vertical="" // NOSONAR
                ></separator><text><b>{process.env.REACT_APP_VERSION}</b></text><text><count data-count=""></count></text></li>
                <li className="separator"></li>
                <li><a href="/logout"><icon></icon><text><T>text.sign_out</T></text></a></li>
            </ul>
        </div>;

    let helpMenu =
        <div className="list_menu ant-dropdown-menu">
            <ul>
                <li onClick={e => { openVideoPopup() }} // NOSONAR
                    ><icon></icon><text>{menuItems[0].help.video}</text></li>
                {menuItems.map((item, i) => {
                    return (
                        !item.help.default &&
                        <Fragment key={i} // NOSONAR
                        >
                            <li className="separator"></li>
                            <li command="user" // NOSONAR
                            >
                                <a href={`/help/${(item.help.fr && culture === "fr") ? item.help.fr : item.help.en}`} target="_blank" rel="noopener noreferrer">
                                    <icon></icon><text>{item.help.title}</text>
                                </a>
                            </li>
                        </Fragment>
                    )
                })}
                {/* <li command="user"><a href={`/help/Brand_EN.pdf`} target="_blank"><icon></icon><text><T>text.brand</T></text></a></li>
                <li className="separator"></li>
                <li command="user"><a href={`/help/SalesNetwork_EN.pdf`} target="_blank"><icon></icon><text><T>text.sales_network</T></text></a></li>
                <li className="separator"></li>
                <li command="user"><a href={`/help/Universe_${culture.toUpperCase()}.pdf`} target="_blank"><icon></icon><text><T>text.universe</T></text></a></li>
                <li className="separator"></li>
                <li command="user"><a href={`/help/Products_EN.pdf`} target="_blank"><icon></icon><text><T>text.products</T></text></a></li>
                <li className="separator"></li>
                <li command="user"><a href={`/help/BillOfMaterials_${culture.toUpperCase()}.pdf`} target="_blank"><icon></icon><text><T>text.bill_of_materials</T></text></a></li>
                <li className="separator"></li>
                <li command="user"><a href={`/help/SpareParts_EN.pdf`} target="_blank"><icon></icon><text><T>text.spare_parts</T></text></a></li> */}
            </ul>
        </div>;

    let location = window.location.pathname.toLowerCase();

    let selectedItem = null;
    return <><toolbar id="mainHeader" className="main_header">
        <nav launcher="" id="launcher" // NOSONAR
        >
            <Scroll>
                <ul>
                    {menuItems.map((item, i) => { // NOSONAR
                        const url = Project.getPageUrl(
                            (item.to && Project.hasRoleAccess(role, item.to))
                                ? item.to
                                : item.subMenu && [...item.subMenu].sort(sm => sm.isDefault ? -1 : 1).find(sub => Project.hasRoleAccess(role, sub.to))?.to) || "";
                        const selected = (url && url.toLowerCase() === location) || (item.detail && location.startsWith(Project.getPageUrl(item.detail) + "/"))
                            || (item.subMenu && item.subMenu.some(sub => location.startsWith(Project.getPageUrl(sub.to)) || (sub.detail && location.startsWith(Project.getPageUrl(sub.detail)))))
                            || (item.pages && item.pages.map(p => Project.getPageUrl(p)).some(p => location.startsWith(p)));
                        if (selected) {
                            document.onkeydown = (e) => {
                                const keyF1 = 'F1';

                                if (e.key === keyF1) {
                                    if (!item.help.default) {
                                        e.preventDefault();
                                        const urlPDF = `/help/${(item.help.fr && culture === "fr") ? item.help.fr : item.help.en}`;
                                        window.open(urlPDF, "_blank");
                                    }

                                    return; // NOSONAR
                                }
                            };
                            selectedItem = {
                                url,
                                subMenu: item.subMenu || []
                            };
                        }
                        if (!url) {
                            return <div key={i} // NOSONAR
                                style={{ display: 'none' }}></div >;
                        }
                        return <li key={i} // NOSONAR
                            className={selected ? "selected" : ""} >
                            <Link to={url} onClick={closeAllPopups} >
                                <text>{item.text}</text>
                            </Link>
                        </li>

                    })}
                </ul>
            </Scroll>
        </nav>
        <LanguageChangeComponent />
        <separator vertical="" // NOSONAR
        ></separator>
        {
            <>

                <Dropdown
                    className=""
                    trigger={['click']}
                    overlay={helpMenu} placement="topRight" // NOSONAR
                    >
                    <div command="help" effect="material" className="button special" data-tip="" // NOSONAR 
                    ><text>Help</text></div>
                </Dropdown>
                <separator vertical="" // NOSONAR
                ></separator>
            </>
        }
        {
            //<>
            //    <div command="notification" effect="material" className="button special micro" data-tip=""><text>95</text></div>
            //    <separator vertical=""></separator>
            //</>
        }
        <Dropdown
            className=""
            trigger={['click']}
            overlay={userMenu} placement="topRight" // NOSONAR
            >
            <div command="userMenu" effect="material" className="button adaptive" // NOSONAR
            ><icon data-tip={userName}>user</icon><text>{userName}</text></div>
        </Dropdown>
    </toolbar>
        {selectedItem && selectedItem.subMenu.length && selectedItem.subMenu.some(sub => Project.hasRoleAccess(role, sub.to)) ?
            <toolbar className="main_header">
                <nav launcher="" // NOSONAR
                >
                    <Scroll>
                        <ul>
                        {selectedItem.subMenu.map((item, i) => {
                            const url = (Project.hasRoleAccess(role, item.to) && Project.getPageUrl(item.to)) || "";
                            return url ? <li key={i} // NOSONAR
                                className={url.toLowerCase() === location || (item.detail && location.startsWith(Project.getPageUrl(item.detail) + "/")) ? "selected" : ""}>
                                <Link to={url} onClick={closeAllPopups} ><text>{item.text}</text></Link>
                            </li> : <div key={i} // NOSONAR
                                style={{ display: 'none' }}></div >
                        })}
                        </ul>
                    </Scroll>
                </nav>
            </toolbar> : <></>}
    </>
}
export default connect(state => ({
    role: state.role,
    culture: state.culture,
    userName: state.userName,
    userToken: state.userToken
}),
    dispatch => ({ closeAllPopups: () => dispatch(closeAllPopups()) }))(withRouter(Menu));