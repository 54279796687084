import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { T } from '../../components/Translations';
import { hideLoader, showLoader } from '../Loader';
import store from '../../store';
import { Ajax } from '~/components/Ajax';

const maxcount = 100;


function ImageWithAuth(props){
    const [src, setSrc] = useState();

    useEffect(() => {
        props.src && Ajax.downloadImage({url: props.src, 
            success: function (imageUrl) {
                setSrc(imageUrl);
            }
        });
    }, [props.src])

    return props.src && <img alt=""
        {...props}
        src={src}
    />
}

const api_url = process.env.REACT_APP_DEV_API_URL ?? process.env.REACT_APP_API_URL;
const api_app = process.env.REACT_APP_DEV_API_APP ?? process.env.REACT_APP_API_APP;


/**
 * the component used in forms for image upload
 * @param {any} props contains: imageUrl, imageField, dataItem, setDataItem, type, alt, showUploadList, listType, className, actionUrl
 * @exports CustomUpload
 */
export default function CustomUpload(props) { // NOSONAR
    const url = (api_url + api_app || "");
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const { imageField, dataItem, setDataItem, type, alt, showUploadList, listType, fileNameField, className, actionUrl, multiple, skipDeleteIcon, keepName, showList, anyFile, acceptTypes, hideLink, formIcon, isLocalUpload, maxHeight, maxWidth, isManifest, minHeight, minWidth, additionalField } = props;
    const [fileList, setFileList] = useState([]);
    const duplicateNames = useRef([]);
    let uploadingCount = useRef(0);
    const uploadRef = useRef(null);
    const canvasRef = useRef(null)

    const customRequest = useCallback(event => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(event.file);
        fileReader.onload = () => {

            event.onSuccess(fileReader.result);
        }
        fileReader.onerror = (error) => {
            event.onError(error);
        }
    }, []);

    const validateSetImageData = useCallback((url, newData, imageField, fileList) => { // NOSONAR
        const loadLocalImage = function(imageUrl){
            const img = new Image();

                img.onload = function () {
                    if (isManifest) {
                        let sizes = `${this.height}x${this.width}`;
    
                        newData[imageField] = { ...newData[imageField], sizes: sizes };
    
                        if (additionalField) {
                            let canvas = canvasRef.current,
                                ctx = canvas.getContext('2d');
    
                            canvas.width = additionalField.width;
                            canvas.height = additionalField.height;
                            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    
                            const dataURI = canvas.toDataURL();
                            sizes = `${canvas.height}x${canvas.width}`
    
                            newData[additionalField.fieldName] = { src: dataURI, type: fileList[0].type, sizes: sizes };
    
                            ctx.clearRect(0, 0, canvas.width, canvas.height);
                            canvas.width = 0
                            canvas.height = 0;
                        }
                    } 
    
                    if (maxWidth || maxHeight || minWidth || maxHeight) {
                        if ((maxHeight && this.height > maxHeight) || (maxWidth && this.width > maxWidth)
                            || (minHeight && this.height < minHeight) || (minWidth && this.width > minWidth)) {
                            message.error(`Invalid size.
                                        ${maxHeight ? `Height max:${maxHeight}px` : ''}
                                        ${maxWidth ? `Width max:${maxWidth}px` : ''}
                                        ${minHeight ? `Height max:${minHeight}px` : ''}
                                        ${minWidth ? `Width max:${minWidth}px` : ''}`); // NOSONAR
                        } else {
                            setDataItem(newData);
                        }
                    } else {
                        setDataItem(newData);
                    }
                }
                
                img.src = imageUrl;
        };

        if (url.indexOf("data:image") === 0){
            loadLocalImage(url);
        } else {
            Ajax.downloadImage({url, 
                success: function (imageUrl) {
                    loadLocalImage(imageUrl);
                }
            })
        }
    }, [maxWidth, maxHeight, minWidth, minHeight, setDataItem, additionalField, isManifest]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => hideLoader("upload");
    }, [])

    useEffect(() => { // NOSONAR
        if (fileList.length) {
            let newData = { ...dataItem };
            let image_url;

            if (multiple) {
                newData[imageField] = [...newData[imageField], ...fileList.map(f => {
                    image_url = url + "/api/images/GetFile?type=Temp&filename=" + f.response.FileName + "&fileDownloadName=" + (f.name || '')
                    return { image_guid: f.response.FileName, name: f.name, image_url }
                })];

            } else {
                if (!isLocalUpload) {
                    image_url = url + "/api/images/GetFile?type=Temp&filename=" + fileList[0].response.FileName + "&fileDownloadName=" + (fileList[0].name || '');
                    newData[imageField] = [{ image_guid: fileList[0].response.FileName, name: fileList[0].name, image_url }];
                } else {
                    if (isManifest) {
                        image_url = fileList[0].response;
                        
                        newData[imageField] = { ...newData[imageField], src: image_url, type: fileList[0].type };
                    } else {
                        image_url = fileList[0].response;
                        newData[imageField] = image_url;
                    }
                }
            }

            if (anyFile) {
                setDataItem(newData);
            } else {
                validateSetImageData(image_url, newData, imageField, fileList);
            }
            
            setFileList([]);
        }
    }, [fileList, keepName]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = useCallback((info) => { // NOSONAR
        if (uploadRef.current) {
            if (info.file.status === 'uploading') {
                return;
            }
            if (info.file.status === 'error') {
                const responseFileInfo = info.file.response?.File;
                message.error((responseFileInfo?.length && responseFileInfo[0]) || `${t('text.unsupported_media_type')}: "${info.file.name}"`)
            }

            if (info.file.status === 'done') {
                if (info.file.response?.HasError) {
                    message.error(info.file.response?.Message);
                    setIsLoading(false);
                    hideLoader("upload");
                    return;
                }
            }
            if (info.fileList.every(f => f.status === 'done' || f.status === 'error' || !f.status)) {
                setFileList(info.fileList.filter(f => f.status === 'done'));
                setIsLoading(false);
                hideLoader("upload");
                if (duplicateNames.current.length) {
                    message.warning(<T args={[duplicateNames.current.length]}>message.duplicate_file_name</T>);
                    duplicateNames.current = [];
                }
                uploadingCount.current = 0
            }
        }
    }, [duplicateNames, t]);

    const removeImage = useCallback(image => {
        let newData = { ...dataItem };
        if (multiple) {
            newData[imageField] = newData[imageField].filter(im => im.image_guid !== image);
        } else if (additionalField) {
            newData[imageField] = [];
            newData[additionalField.fieldName] = [];
        } else {
            newData[imageField] = [];
        }

        setDataItem(newData);
    }, [dataItem, setDataItem, imageField, multiple, additionalField]);

    const beforeUpload = useCallback((file) => {
        showLoader("upload");
        setIsLoading(true);
        if (keepName && dataItem[imageField].some(im => im.name === file.name)) {
            duplicateNames.current = [...duplicateNames.current, file.name];
            return false;
        }
        uploadingCount.current += 1;
        if (uploadingCount.current > maxcount) {
            if (uploadingCount.current === maxcount + 1) {
                message.warning(<T args={[maxcount]}>message.only_max_count_file_will_be_processed</T>, 2)
            }
            return false;
        }

        if (!keepName) { // NOSONAR
            return true
        }
        return true;
    }, [dataItem, imageField, keepName]);

    const uploadButton = (
        <div>
            {
                //<Icon type={isLoading ? 'loading' : 'plus'} />
                isLoading ? <LoadingOutlined /> : (formIcon ? <button className="button micro" type='button'><icon><icon>plus</icon></icon></button > : <PlusOutlined />) // NOSONAR
            }
            <div className="ant-upload-text"><T>text.upload</T></div>
        </div>
    );

    const innerContent = dataItem[imageField] && (dataItem[imageField][0] || dataItem[imageField].src) && <div className={className || "avatar-uploader"}>
        <span>
            {(anyFile ? (hideLink ? <></> : <a target='_blank' href={dataItem[imageField][0].image_url} >{fileNameField ? dataItem[fileNameField] : (dataItem[imageField][0].name || dataItem[imageField][0].image_guid)}</a>) : /*eslint-disable-line react/jsx-no-target-blank*/ // NOSONAR
                <ImageWithAuth 
                    src={isLocalUpload ? (isManifest ? dataItem[imageField].src : dataItem[imageField] ): dataItem[imageField][0].image_url // NOSONAR
                    } 
                    alt={alt || dataItem[imageField].image_url // NOSONAR
                    } 
                    style={{ width: '100%', objectFit: 'contain' }} 
                />
            )}
            </span>
        {!skipDeleteIcon ? <button className="button micro" type='button' onClick={
            (e) => {
                e.stopPropagation();
                removeImage(dataItem[imageField]);
            }
        }>< icon>delete</icon></button > : <></>}</div>
    return <>
        
        {!fileList.length && ((!multiple && innerContent) || <Upload ref={uploadRef}
            accept={acceptTypes ? acceptTypes : (anyFile ? '.png, .jpg, .gif, .jpeg, .pdf, .mp4, .mov, .avi, .doc, .docx, .xls, .xlsx' : (isLocalUpload ? '.svg, ' : '') + '.png, .jpg, .gif, .jpeg')} // NOSONAR
            listType={listType || "picture-card"}
            multiple={multiple}
            //  fileList={fileList}
            // className={className || "avatar-uploader"}
            headers={
                {
                    authorization: `Bearer ${store.getState().userToken}`,
                    'X-Requested-With': null
                }
            }
            className={className}
            {...(isLocalUpload ? { customRequest } : { action: url + (actionUrl || "/api/Images/UploadFile?type=" + type) })}
            showUploadList={showUploadList || false}

            beforeUpload={beforeUpload}// { console.log(data, "beforeupload"); return data[0].name !='download (1) - Copy.jpeg'; }}
            //onSuccess={(...test) => { console.log("onsuccess", test); }}
            onChange={handleChange}
        >
            {uploadButton}
        </Upload>)}
        {additionalField && <canvas ref={canvasRef} width={0} height={0}></canvas>}
        {multiple && showList ? dataItem[imageField].map((m, i) => {
            const imageKey = m.image_guid;
            const deleteButton = !skipDeleteIcon && <button className="button mini" type='button' onClick={() => { removeImage(imageKey); }}><icon>delete</icon></button >;
            return <div key={imageKey} className={className || "avatar-uploader"} >
                <span>{anyFile ? (hideLink ? <></> : <a target='_blank' href={m.image_url} >( m.name || m.image_guid )</a>) : <ImageWithAuth src={m.image_url} style={{ width: '100%', objectFit: 'contain' }} alt="" />}</span>{/*eslint-disable-line react/jsx-no-target-blank*/ // NOSONAR
                }
                {deleteButton}
            </div >
        }) : <></>}</>
}